import React from 'react';
import styled from 'styled-components'
import udemy from '../../content/assets/udemy-icon.svg'
import udemyWhite from '../../content/assets/udemy-icon-white.svg'

const Wrapper = styled.a`
    color: white;
    padding: 0.8rem 1rem;
    font-size: 0.8rem;
    background: #ec5252;
    border-radius: 0.2rem;
    font-weight: bold;
`

const Button = ({ link, text, type }) => {
  if (type === 'text') {
    return (
      <a href={link} style={{
        fontSize: `0.8rem`,
        display: `flex`,
        alignItems: `center`,
        background: `white`,
        width: `max-content`,
        borderRadius: `0.2rem`,
        color: `#e61b1b`,
        fontWeight: `bold`,
        whiteSpace: `nowrap`
      }}>
        <img src={udemy} alt="Scalable CSS Logo" style={{ height: `0.8rem`, marginBottom: 0, marginRight: `0.2rem` }} />
        { text }
      </a>
    )
  } else {
    return (
      <Wrapper href={link}>
        <img src={udemyWhite} alt="Scalable CSS Logo" style={{ height: `0.8rem`, marginBottom: `-0.1rem`, marginRight: `0.4rem` }} />
        { text }
      </Wrapper>
    )
  }
}

export default Button
import React from 'react';
import styled from 'styled-components'
import Signup from './Signup';
import Button from './Button';
import { rhythm, scale } from '../utils/typography'

const CTAWrapper = styled.div`
    background: #ec525214;
    border-radius: 4px;
    border: 1px solid #ec52522b;
    margin: 3rem 0 4rem 0;
    padding: 1.5rem;
    max-width: 720px;

    @media (min-width: 768px) { 
      padding: 2rem;
    }
`

const CTA = ({cta}) => {
  let form;
  switch (cta) {
    case 'styled-components-cheat-sheet':
      form = {
        title: 'Want to master Styled Components in React?',
        subTitle: `Levelling up your React and CSS skills in 2021 is a great idea. Here's why:`,
        bullet1: `Increase your employability and value as a React developer`,
        bullet2: `Learn how to style your React apps in a clean, efficient method`,
        bullet3: `Get a deep understanding of a CSS-in-JS library`,
        buttonText: 'View recommended course on Udemy',
        link: 'https://click.linksynergy.com/link?id=Wudf0pRqSy8&offerid=507388.1797176&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-styled-components%2F'
      };
      break;
    default:
      form = {
        title: 'Want to become an advanced CSS & Sass developer?',
        subTitle: `Levelling up your CSS & Sass skills in 2021 is a great idea. Here's why:`,
        bullet1: `Increase your employability and value as a front-end developer`,
        bullet2: `Learn how to style your websites and applications in clean, efficient code `,
        bullet3: `Use tooling and techniques like BEM, flexbox, grid & more to improve your code`,
        buttonText: 'View recommended course on Udemy',
        link: 'https://click.linksynergy.com/link?id=Wudf0pRqSy8&offerid=507388.1026604&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fadvanced-css-and-sass%2F'
      };
  }
  return (
    <CTAWrapper id={form.id}>
      <h3 style={{ marginTop: 0 }}>{form.title}</h3>
      <p style={{ marginTop: rhythm(-.5), marginBottom: `0.4rem` }}>
        {form.subTitle}
      </p>
      <ul style={{
        marginBottom: `1.4rem`
      }}>
        <li style={{
        marginBottom: `0.2rem`,
      }}>{form.bullet1}</li>
        <li style={{
        marginBottom: `0.2rem`,
      }}>{form.bullet2}</li>
        <li style={{
        marginBottom: `0.2rem`,
      }}>{form.bullet3}</li>
      </ul>
      <Button link={form.link} text={form.buttonText} />
    </CTAWrapper>
  )
}

export default CTA;
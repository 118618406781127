import Typography from 'typography'
import sutroTheme from 'typography-theme-sutro'
sutroTheme.baseFontSize = '16px' // was 18px.
sutroTheme.googleFonts = [
  {
    name: 'IBM Plex Sans',
    styles: [
      '700',
    ],
  },
]
sutroTheme.headerFontFamily = ['IBM Plex Sans', 'Helvetica']
sutroTheme.overrideThemeStyles = ({ rhythm }, options) => ({
  "h1,h2,h3,h4,h5,h6": {
    marginTop: rhythm(0),
  },
  "h2,h3,h4,h5,h6": {
    marginTop: rhythm(2),
  },
  a: {
    color: "#1d69b4",
    textDecoration: "none",
  },
  ol: {
    paddingLeft: '1rem',
  },
  ul: {
    paddingLeft: '1rem',
  },
  code: {
    fontSize: 'inherit',
    background: '#1d69b417',
    padding: '0.2rem',
    borderRadius: '4px',
  }
})

const typography = new Typography(sutroTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale

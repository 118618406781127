import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rhythm, scale } from '../utils/typography'
import logo from '../../content/assets/scalable_css_logo.svg'

const StyledNav = styled.nav`
  background: #292929;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

const Nav = () => {
  return (
    <StyledNav>
      <h3
        style={{
          margin: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <img src={logo} alt="Scalable CSS Logo" style={{ margin: 0 }} />
        </Link>
      </h3>
    </StyledNav>
  )
}

export default Nav
import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import CTA from './CTA';
import Nav from './Nav';
import styled from 'styled-components'

import { rhythm, scale } from '../utils/typography'
const shortcodes = { CTA };

const Container = styled.div`
  pre {
    font-size: 0.8rem;
    margin-bottom: 1.78rem;
  }
`

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    return (
      <>
        <Helmet>
          <script>
            
          </script>
        </Helmet>
        <Container>
          <Nav />
          <MDXProvider components={shortcodes}>
            <div>
              {children}
              <footer style={{
                    margin: `0px auto`,
                    padding: `1rem`,
                    textAlign: `center`
                  }}> 
                © Scalable CSS {new Date().getFullYear()}, built with{` ❤️ in `}London, UK.
              </footer>
            </div>
          </MDXProvider>
        </Container>
      </>
    )
  }
}

export default Layout
